@font-face {
  font-family: 'ITC Avant Garde Gothic Pro';
  src:
    local('Inter Regular'),
    url('./vendor/fonts/ITCAvantGardeGothicPro/Laranjh0.ttf') format('ttf');
  font-style: normal;
}

body {
  margin: 0;
  font-family:
    'ITC Avant Garde Gothic Pro',
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
